<template>
  <div class='container'>
    <h2>Edit SuggestedProducts</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    
    <button @click='deleteSuggestedProducts()' type='button' class='btn btn-danger'>Delete</button>
	
	<div class='row'>
<div class='col-md-4'>
   <label class for='Heading'>Heading</label>
   <input
     class='form-control'
     id='Heading'
     name='Heading'
     v-model='editingSuggestedProducts.Heading'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='Copy'>Copy</label>
   <input
     class='form-control'
     id='Copy'
     name='Copy'
     v-model='editingSuggestedProducts.Copy'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
    <label class for='Image'>Image</label>
    <div v-if='!editingImage'>
      <img v-bind:src='getImageSource(editingSuggestedProducts)' class='previewImage'>
      <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
    </div>
    <div v-if='editingImage'>
      <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
      <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
      <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl'/>
    </div>
  </div>
<div class='col-md-4'>
   <label class for='ImageMeta'>ImageMeta</label>
   <input
     class='form-control'
     id='ImageMeta'
     name='ImageMeta'
     v-model='editingSuggestedProducts.ImageMeta'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='DisplayOrder'>DisplayOrder</label>
   <input
     class='form-control'
     id='DisplayOrder'
     name='DisplayOrder'
     v-model='editingSuggestedProducts.DisplayOrder'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='Link'>Link</label>
   <input
     class='form-control'
     id='Link'
     name='Link'
     v-model='editingSuggestedProducts.Link'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='LinkFriendly'>LinkFriendly</label>
   <input
     class='form-control'
     id='LinkFriendly'
     name='LinkFriendly'
     v-model='editingSuggestedProducts.LinkFriendly'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='LastUpdated'>LastUpdated</label>
   <input
     class='form-control'
     id='LastUpdated'
     name='LastUpdated'
     v-model='editingSuggestedProducts.LastUpdated'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
  </div>
    <br/>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
 
export default { 
  name: 'EditSuggestedProducts', 
 
  data() { 
    return { 
      imageBytes: {}, 
      selectedFile: {}, 
      originalData: {}, 
      modifiedData: {}, 
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
      editingImage: false 
    }; 
  }, 
  components: { 
    FileUploader 
  }, 
  created() { 
    this.originalData = JSON.parse(JSON.stringify(this.editingSuggestedProducts)); 
    this.ImageBytes = this.editingSuggestedProducts.Image; 
  }, 
 
  props: ['editingSuggestedProducts'], 
 
  methods: { 
    toggleView: function() { 
      this.uploadedImageUrl = null;
      this.$emit('closeEditSuggestedProducts'); 
    }, 
 
    save: function() { 
    var data = JSON.parse(JSON.stringify(this.editingSuggestedProducts));
      var payload = {
          data: data,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('editSuggestedProductsSuccess') 
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('editSuggestedProducts', payload);
    }, 
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    deleteSuggestedProducts: function() { 
     var payload = {
      data: this.editingSuggestedProducts,
      success: response => {
        this.$emit('editSuggestedProductsSuccess') 
      },
      error: error => { miniToastr['error'](error, 'Error', 1000, null)}
    }
    this.$store.dispatch('deleteSuggestedProducts', payload);
    } 
  } 
}; 
</script> 
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
