<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addSuggestedProductsModal' hide-footer>
        <addSuggestedProducts @closeAddSuggestedProducts='toggleAddSuggestedProducts()' @addSuggestedProductsSuccess='addSuggestedProductsSuccess()'></addSuggestedProducts>
      </b-modal>
      <b-modal ref='editSuggestedProductsModal' hide-footer>
        <editSuggestedProducts
          :editingSuggestedProducts='currentSuggestedProducts'
          @closeEditSuggestedProducts='toggleEditSuggestedProducts()'
          @editSuggestedProductsSuccess='editSuggestedProductsSuccess()'
        ></editSuggestedProducts>
      </b-modal>
      <div class='row'>
        <h2>SuggestedProducts</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddSuggestedProducts()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addSuggestedProducts from '../../components/addSuggestedProducts';
import editSuggestedProducts from '../../components/editSuggestedProducts';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Copy',
                field: 'Copy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Link',
                field: 'Link',
                sort: 'asc',
              },
              {
                label: 'LinkFriendly',
                field: 'LinkFriendly',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addSuggestedProductsOpen: false,
      editSuggestedProductsOpen: false,
      currentSuggestedProducts: {}
    };
  },
  components: {
    addSuggestedProducts,
    editSuggestedProducts,
    Datatable,
  },
  created: function() {
    this.getSuggestedProducts();
    this.addSuggestedProductsOpen = false;
    this.editSuggestedProductsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getSuggestedProducts() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getSuggestedProducts', payload);
    },
    editItem(SuggestedProducts) {
      this.toggleEditSuggestedProducts();
      this.currentSuggestedProducts = SuggestedProducts;
    },
    toggleAddSuggestedProducts() {
      if(this.addSuggestedProductsOpen)
      {
        this.$refs.addSuggestedProductsModal.hide()
      }
      else{
        this.$refs.addSuggestedProductsModal.show()
      }
      this.addSuggestedProductsOpen = !this.addSuggestedProductsOpen;
    },
    addSuggestedProductsSuccess() {
      this.toggleAddSuggestedProducts();
      miniToastr['success']('SuggestedProducts Added', 'Success', 1000, null);
      this.getSuggestedProducts();
    },
    toggleEditSuggestedProducts() {
      if(this.editSuggestedProductsOpen)
      {
        this.$refs.editSuggestedProductsModal.hide()
      }
      else{
        this.$refs.editSuggestedProductsModal.show()
      }
      this.editSuggestedProductsOpen = !this.editSuggestedProductsOpen;
    },
    editSuggestedProductsSuccess() {
      this.toggleEditSuggestedProducts();
      miniToastr['success']('SuggestedProducts Edited', 'Success', 1000, null);
      this.getSuggestedProducts();
    }
  }
};
</script>
<style scoped>
.SuggestedProductsThumbnail {
  height: 50px;
}
</style>
